import { red } from '@material-ui/core/colors';
import {
  createMuiTheme,
  // responsiveFontSizes,
} from '@material-ui/core/styles';

// A custom theme for this app
let theme = createMuiTheme({
  palette: {
    primary: {
      main: '#B90605',
      dark: '#990200',
    },
    secondary: {
      main: '#F6F6F6',
      dark: '#E8E8E8',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fdfdfd',
      paper: '#fdfdfd',
    },
    text: {
      primary: 'rgb(51,51,51)',
    },
  },
  typography: {
    fontFamily: 'proxima-nova',
    button: {
      fontWeight: 'bold',
    },
  },
  shape: {
    borderRadius: 0,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1300,
      xl: 1920,
    },
  },
});

theme.typography.h1 = {
  fontSize: '3.125rem',
  textTransform: 'uppercase',
  letterSpacing: -1,
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.875rem',
  },
};

theme.typography.h2 = {
  fontSize: '1.5rem',
  textTransform: 'capitalize',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.125rem',
  },
};

theme.typography.body1 = {
  fontFamily: 'proxima-nova',
  fontSize: '1.125rem',
  fontWeight: 400,
  lineHeight: 1.6,
  [theme.breakpoints.down('sm')]: {
    fontSize: '1rem',
  },
};

theme.typography.body2 = {
  fontFamily: 'proxima-nova',
  fontSize: '1.125rem',
  fontWeight: 300,
  lineHeight: 1.6,
  [theme.breakpoints.down('sm')]: {
    fontSize: '1rem',
  },
};

export default theme;
